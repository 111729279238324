import { arrow_up,arrow_down,sound_on,sound_off,reelUp,reelDown } from "./main.js";
//Reels Array
const reels = document.querySelectorAll('.reel')
const reel_mute = document.querySelector('.reel_mute')
const reel_mute_img = document.querySelector('.reel_mute img')
var toggle_sound_icon = true
var video = document.querySelectorAll('video')
var id=0;
var muted = false


//Loop Array

toggle_sound_icon=!toggle_sound_icon;

function reelsFunction(id){
    reels.forEach((items,index)=>{
        items.setAttribute('data-id',index)
        reel_mute.setAttribute('data-id',id)
        
        var muteID = reel_mute.getAttribute('data-id')
        var itemID = items.getAttribute('data-id')

       
    
        if(muteID == itemID){
            const current_video = items.querySelector('video')
            current_video.play()
            current_video.currentTime = 0
            
        }
    
    
    })
}

function muteFunction(id,mute){
    reels.forEach((items,index)=>{
        items.setAttribute('data-id',index)
        reel_mute.setAttribute('data-id',id)
        
        var muteID = reel_mute.getAttribute('data-id')
        var itemID = items.getAttribute('data-id')

       
    
        if(mute==true){
            if(muteID == itemID){
                const current_video = items.querySelector('video')
                current_video.play()
                current_video.muted=false
                
            }
        }else{
            if(muteID == itemID){
                const current_video = items.querySelector('video')
                current_video.play()
                current_video.muted=true
                
            }
        }
       

    
        
    
    
    })
}

reel_mute.onclick = ()=>{
       
    
    if(toggle_sound_icon=!toggle_sound_icon){
        reel_mute_img.setAttribute('src',sound_off)
    }else{
        reel_mute_img.setAttribute('src',sound_on)
    }


    muted=!muted
    muteFunction(id,muted)
   console.log(muted)
}

arrow_up.onclick=()=>{
    
    
    reelUp()
    

    if(id > 0){
        video.forEach(videos=>{
            videos.muted = true;
        })
        id--
        console.log(id)
        reelsFunction(id)
       
        
        
    }

  

    muteFunction(id,muted)
    
    
}



arrow_down.onclick=()=>{
    
    
    reelDown()
    
   

    if(id < reels.length-1){
        video.forEach(videos=>{
            videos.muted = true;
        })
        id++
        reelsFunction(id)
        console.log(id)
        
    }

    
    muteFunction(id,muted)
    
    
}


